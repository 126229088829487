<template>
    <div class="container rtl">
        <div class="row py-3">
            <div class="col">
                <form @submit.prevent="addtown" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی ناوچە</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ناوی ناوچە</label>
                            <input v-model="town.town_name_ku" class="form-control" placeholder="ناوی ناوچە" type="text"
                                required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>اسم المحافضة</label>
                            <input v-model="town.town_name_ar" class="form-control" placeholder="اسم المحافضة"
                                type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>Town Name</label>
                            <input v-model="town.town_name_en" class="form-control" placeholder="Town Name" type="text"
                                required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> شار </label>
                            <select v-model="town.city_id" class="form-control" required>
                                <option :value="item.city_id" v-for="(item, index) in cities" :key="index">
                                    {{item.city_name_ku}}</option>
                            </select>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary m-2">زیادکردن</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی ناوچە</th>
                            <th scope="col">شار</th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in alltowns" :key="index">
                            <td>{{item.town_name_ku}}</td>
                            <td>{{item.city_name_ku}}</td>                          
                            <td>
                                <button @click="opentown(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="opentown(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی ناوچە</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی ناوچە؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletetown">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatetown">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی ناوچە</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>ناوی ناوچە</label>
                                    <input v-model="selected_town.town_name_ku" class="form-control form-control-lg"
                                        placeholder="ناوی ناوچە" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>اسم المحافضة</label>
                                    <input v-model="selected_town.town_name_ar" class="form-control form-control-lg"
                                        placeholder="اسم المحافضة" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>town Name</label>
                                    <input v-model="selected_town.town_name_en" class="form-control form-control-lg"
                                        placeholder="town Name" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label> شار </label>
                                    <select v-model="selected_town.city_id" class="form-control" required>
                                        <option :value="item.city_id" v-for="(item, index) in cities" :key="index">
                                            {{item.city_name_ku}}</option>
                                    </select>
                                </div>


                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_town: {},
                town: {},
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            alltowns() {
                return this.$store.getters.towns
            },
            cities() {
                return this.$store.getters.cities
            }
        },
        methods: {
            gettowns() {
                $('#dataTable').DataTable().destroy();
                axios.get('town/read.php')
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.towns = r.data.rows
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addtown() {
                let params = this.town
                axios.post('town/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.town = {};
                            }
                            this.gettowns()
                            this.msg = {
                                model: true,
                                text: 'ناوچە زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletetown() {
                let params = {
                    town_id: this.selected_town.town_id
                }
                axios.post('town/delete.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.gettowns()
                            this.msg = {
                                model: true,
                                text: ' ناوچە سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updatetown() {
                let params = this.selected_town
                axios.post('town/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.gettowns()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'ناوچە نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opentown(item, type) {
                this.selected_town = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            this.gettowns()
        },
    }
</script>